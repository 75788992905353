define("lodash/_baseToNumber", ["exports", "lodash/isSymbol"], function (_exports, _isSymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Used as references for various `Number` constants. */
  var NAN = 0 / 0;

  /**
   * The base implementation of `_.toNumber` which doesn't ensure correct
   * conversions of binary, hexadecimal, or octal string values.
   *
   * @private
   * @param {*} value The value to process.
   * @returns {number} Returns the number.
   */
  function baseToNumber(value) {
    if (typeof value == 'number') {
      return value;
    }
    if ((0, _isSymbol.default)(value)) {
      return NAN;
    }
    return +value;
  }
  var _default = baseToNumber;
  _exports.default = _default;
});