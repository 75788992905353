define("lodash/_mapCacheClear", ["exports", "lodash/_Hash", "lodash/_ListCache", "lodash/_Map"], function (_exports, _Hash, _ListCache, _Map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Removes all key-value entries from the map.
   *
   * @private
   * @name clear
   * @memberOf MapCache
   */
  function mapCacheClear() {
    this.size = 0;
    this.__data__ = {
      'hash': new _Hash.default(),
      'map': new (_Map.default || _ListCache.default)(),
      'string': new _Hash.default()
    };
  }
  var _default = mapCacheClear;
  _exports.default = _default;
});