define("lodash/flattenDepth", ["exports", "lodash/_baseFlatten", "lodash/toInteger"], function (_exports, _baseFlatten, _toInteger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Recursively flatten `array` up to `depth` times.
   *
   * @static
   * @memberOf _
   * @since 4.4.0
   * @category Array
   * @param {Array} array The array to flatten.
   * @param {number} [depth=1] The maximum recursion depth.
   * @returns {Array} Returns the new flattened array.
   * @example
   *
   * var array = [1, [2, [3, [4]], 5]];
   *
   * _.flattenDepth(array, 1);
   * // => [1, 2, [3, [4]], 5]
   *
   * _.flattenDepth(array, 2);
   * // => [1, 2, 3, [4], 5]
   */
  function flattenDepth(array, depth) {
    var length = array == null ? 0 : array.length;
    if (!length) {
      return [];
    }
    depth = depth === undefined ? 1 : (0, _toInteger.default)(depth);
    return (0, _baseFlatten.default)(array, depth);
  }
  var _default = flattenDepth;
  _exports.default = _default;
});