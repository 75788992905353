define("lodash/_nativeKeys", ["exports", "lodash/_overArg"], function (_exports, _overArg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Built-in method references for those with the same name as other `lodash` methods. */
  var nativeKeys = (0, _overArg.default)(Object.keys, Object);
  var _default = nativeKeys;
  _exports.default = _default;
});