define("lodash/_baseIsRegExp", ["exports", "lodash/_baseGetTag", "lodash/isObjectLike"], function (_exports, _baseGetTag, _isObjectLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** `Object#toString` result references. */
  var regexpTag = '[object RegExp]';

  /**
   * The base implementation of `_.isRegExp` without Node optimizations.
   *
   * @private
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `value` is a regexp, else `false`.
   */
  function baseIsRegExp(value) {
    return (0, _isObjectLike.default)(value) && (0, _baseGetTag.default)(value) == regexpTag;
  }
  var _default = baseIsRegExp;
  _exports.default = _default;
});