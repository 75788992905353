define("ember-responsive/helpers/media", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _helper.default.extend({
    init() {
      this._super(...arguments);
      this.get('media').on('mediaChanged', () => {
        this.recompute();
      });
    },
    media: (0, _service.inject)(),
    compute(_ref) {
      let [prop] = _ref;
      return (0, _object.get)(this, `media.${prop}`);
    }
  });
  _exports.default = _default;
});