define("lodash/_mapCacheGet", ["exports", "lodash/_getMapData"], function (_exports, _getMapData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets the map value for `key`.
   *
   * @private
   * @name get
   * @memberOf MapCache
   * @param {string} key The key of the value to get.
   * @returns {*} Returns the entry value.
   */
  function mapCacheGet(key) {
    return (0, _getMapData.default)(this, key).get(key);
  }
  var _default = mapCacheGet;
  _exports.default = _default;
});