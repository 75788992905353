define("lodash/chunk", ["exports", "lodash/_baseSlice", "lodash/_isIterateeCall", "lodash/toInteger"], function (_exports, _baseSlice, _isIterateeCall, _toInteger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Built-in method references for those with the same name as other `lodash` methods. */
  var nativeCeil = Math.ceil,
    nativeMax = Math.max;

  /**
   * Creates an array of elements split into groups the length of `size`.
   * If `array` can't be split evenly, the final chunk will be the remaining
   * elements.
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Array
   * @param {Array} array The array to process.
   * @param {number} [size=1] The length of each chunk
   * @param- {Object} [guard] Enables use as an iteratee for methods like `_.map`.
   * @returns {Array} Returns the new array of chunks.
   * @example
   *
   * _.chunk(['a', 'b', 'c', 'd'], 2);
   * // => [['a', 'b'], ['c', 'd']]
   *
   * _.chunk(['a', 'b', 'c', 'd'], 3);
   * // => [['a', 'b', 'c'], ['d']]
   */
  function chunk(array, size, guard) {
    if (guard ? (0, _isIterateeCall.default)(array, size, guard) : size === undefined) {
      size = 1;
    } else {
      size = nativeMax((0, _toInteger.default)(size), 0);
    }
    var length = array == null ? 0 : array.length;
    if (!length || size < 1) {
      return [];
    }
    var index = 0,
      resIndex = 0,
      result = Array(nativeCeil(length / size));
    while (index < length) {
      result[resIndex++] = (0, _baseSlice.default)(array, index, index += size);
    }
    return result;
  }
  var _default = chunk;
  _exports.default = _default;
});