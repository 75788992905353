define("lodash/_nativeCreate", ["exports", "lodash/_getNative"], function (_exports, _getNative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Built-in method references that are verified to be native. */
  var nativeCreate = (0, _getNative.default)(Object, 'create');
  var _default = nativeCreate;
  _exports.default = _default;
});