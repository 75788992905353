define("ember-cli-google-tag-manager/instance-initializers/gtm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    // Do not inject anythin in the router if appId is not present
    var environment = application.resolveRegistration('config:environment') || {};
    var config = environment.googleTagManager;
    var appId = config && config.appId;
    if (!appId) {
      return;
    }
    var router = application.lookup('service:router');
    var gtm = application.lookup('service:gtm');
    router.on('routeDidChange', function () {
      gtm.trackPageView(this.currentURL);
    });
  }
  var _default = {
    name: 'gtm',
    initialize: initialize
  };
  _exports.default = _default;
});