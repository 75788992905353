define("lodash/_castFunction", ["exports", "lodash/identity"], function (_exports, _identity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Casts `value` to `identity` if it's not a function.
   *
   * @private
   * @param {*} value The value to inspect.
   * @returns {Function} Returns cast function.
   */
  function castFunction(value) {
    return typeof value == 'function' ? value : _identity.default;
  }
  var _default = castFunction;
  _exports.default = _default;
});