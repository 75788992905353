define("lodash/sortedLastIndex", ["exports", "lodash/_baseSortedIndex"], function (_exports, _baseSortedIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This method is like `_.sortedIndex` except that it returns the highest
   * index at which `value` should be inserted into `array` in order to
   * maintain its sort order.
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Array
   * @param {Array} array The sorted array to inspect.
   * @param {*} value The value to evaluate.
   * @returns {number} Returns the index at which `value` should be inserted
   *  into `array`.
   * @example
   *
   * _.sortedLastIndex([4, 5, 5, 5, 6], 5);
   * // => 4
   */
  function sortedLastIndex(array, value) {
    return (0, _baseSortedIndex.default)(array, value, true);
  }
  var _default = sortedLastIndex;
  _exports.default = _default;
});