define("lodash/_getMatchData", ["exports", "lodash/_isStrictComparable", "lodash/keys"], function (_exports, _isStrictComparable, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets the property names, values, and compare flags of `object`.
   *
   * @private
   * @param {Object} object The object to query.
   * @returns {Array} Returns the match data of `object`.
   */
  function getMatchData(object) {
    var result = (0, _keys.default)(object),
      length = result.length;
    while (length--) {
      var key = result[length],
        value = object[key];
      result[length] = [key, value, (0, _isStrictComparable.default)(value)];
    }
    return result;
  }
  var _default = getMatchData;
  _exports.default = _default;
});