define("lodash/_createSet", ["exports", "lodash/_Set", "lodash/noop", "lodash/_setToArray"], function (_exports, _Set, _noop, _setToArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Used as references for various `Number` constants. */
  var INFINITY = 1 / 0;

  /**
   * Creates a set object of `values`.
   *
   * @private
   * @param {Array} values The values to add to the set.
   * @returns {Object} Returns the new set.
   */
  var createSet = !(_Set.default && 1 / (0, _setToArray.default)(new _Set.default([, -0]))[1] == INFINITY) ? _noop.default : function (values) {
    return new _Set.default(values);
  };
  var _default = createSet;
  _exports.default = _default;
});