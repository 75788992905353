define("lodash/_baseIsMap", ["exports", "lodash/_getTag", "lodash/isObjectLike"], function (_exports, _getTag, _isObjectLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** `Object#toString` result references. */
  var mapTag = '[object Map]';

  /**
   * The base implementation of `_.isMap` without Node optimizations.
   *
   * @private
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `value` is a map, else `false`.
   */
  function baseIsMap(value) {
    return (0, _isObjectLike.default)(value) && (0, _getTag.default)(value) == mapTag;
  }
  var _default = baseIsMap;
  _exports.default = _default;
});