define("lodash/_getData", ["exports", "lodash/_metaMap", "lodash/noop"], function (_exports, _metaMap, _noop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets metadata for `func`.
   *
   * @private
   * @param {Function} func The function to query.
   * @returns {*} Returns the metadata for `func`.
   */
  var getData = !_metaMap.default ? _noop.default : function (func) {
    return _metaMap.default.get(func);
  };
  var _default = getData;
  _exports.default = _default;
});