define("ember-cli-fastboot/services/fastboot", ["exports", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/debug", "@ember/service"], function (_exports, _application, _object, _computed, _debug, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get, ember/require-computed-property-dependencies, ember/require-return-from-computed, prettier/prettier */
  /* global FastBoot */

  const RequestObject = _object.default.extend({
    init() {
      this._super(...arguments);
      let request = this.request;
      delete this.request;
      this.method = request?.method;
      this.body = request?.body;
      this.cookies = request?.cookies;
      this.headers = request?.headers;
      this.queryParams = request?.queryParams;
      this.path = request?.path;
      this.protocol = request?.protocol;
      this._host = function () {
        return request?.host();
      };
    },
    host: (0, _object.computed)(function () {
      return this._host();
    })
  });
  const Shoebox = _object.default.extend({
    put(key, value) {
      (false && !(this.get('fastboot.isFastBoot')) && (0, _debug.assert)('shoebox.put is only invoked from the FastBoot rendered application', this.get('fastboot.isFastBoot')));
      (false && !(typeof key === 'string') && (0, _debug.assert)('the provided key is a string', typeof key === 'string'));
      let fastbootInfo = this.get('fastboot._fastbootInfo');
      if (!fastbootInfo.shoebox) {
        fastbootInfo.shoebox = {};
      }
      fastbootInfo.shoebox[key] = value;
    },
    retrieve(key) {
      if (this.get('fastboot.isFastBoot')) {
        let shoebox = this.get('fastboot._fastbootInfo.shoebox');
        if (!shoebox) {
          return;
        }
        return shoebox[key];
      }
      let shoeboxItem = this.get(key);
      if (shoeboxItem) {
        return shoeboxItem;
      }
      let el = document.querySelector(`#shoebox-${key}`);
      if (!el) {
        return;
      }
      let valueString = el.textContent;
      if (!valueString) {
        return;
      }
      shoeboxItem = JSON.parse(valueString);
      this.set(key, shoeboxItem);
      return shoeboxItem;
    }
  });
  const FastBootService = _service.default.extend({
    isFastBoot: typeof FastBoot !== 'undefined',
    isFastboot: (0, _object.computed)(function () {
      (false && !(false) && (0, _debug.assert)('The fastboot service does not have an `isFastboot` property. This is likely a typo. Please use `isFastBoot` instead.', false));
    }),
    init() {
      this._super(...arguments);
      let shoebox = Shoebox.create({
        fastboot: this
      });
      this.set('shoebox', shoebox);
    },
    response: (0, _computed.readOnly)('_fastbootInfo.response'),
    metadata: (0, _computed.readOnly)('_fastbootInfo.metadata'),
    request: (0, _object.computed)(function () {
      if (!this.isFastBoot) return null;
      return RequestObject.create({
        request: (0, _object.get)(this, '_fastbootInfo.request')
      });
    }),
    // this getter/setter pair is to avoid deprecation from [RFC - 680](https://github.com/emberjs/rfcs/pull/680)
    _fastbootInfo: (0, _object.computed)({
      get() {
        if (this.__fastbootInfo) {
          return this.__fastbootInfo;
        }
        return (0, _application.getOwner)(this).lookup('info:-fastboot');
      },
      set(_key, value) {
        this.__fastbootInfo = value;
        return value;
      }
    }),
    deferRendering(promise) {
      (false && !(typeof promise.then === 'function') && (0, _debug.assert)('deferRendering requires a promise or thennable object', typeof promise.then === 'function'));
      this._fastbootInfo.deferRendering(promise);
    }
  });
  var _default = FastBootService;
  _exports.default = _default;
});