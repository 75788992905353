define("lodash/_castRest", ["exports", "lodash/_baseRest"], function (_exports, _baseRest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A `baseRest` alias which can be replaced with `identity` by module
   * replacement plugins.
   *
   * @private
   * @type {Function}
   * @param {Function} func The function to apply a rest parameter to.
   * @returns {Function} Returns the new function.
   */
  var castRest = _baseRest.default;
  var _default = castRest;
  _exports.default = _default;
});