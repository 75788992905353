define("lodash/_baseEach", ["exports", "lodash/_baseForOwn", "lodash/_createBaseEach"], function (_exports, _baseForOwn, _createBaseEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.forEach` without support for iteratee shorthands.
   *
   * @private
   * @param {Array|Object} collection The collection to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @returns {Array|Object} Returns `collection`.
   */
  var baseEach = (0, _createBaseEach.default)(_baseForOwn.default);
  var _default = baseEach;
  _exports.default = _default;
});