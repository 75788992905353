define("lodash/_baseIsDate", ["exports", "lodash/_baseGetTag", "lodash/isObjectLike"], function (_exports, _baseGetTag, _isObjectLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** `Object#toString` result references. */
  var dateTag = '[object Date]';

  /**
   * The base implementation of `_.isDate` without Node optimizations.
   *
   * @private
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `value` is a date object, else `false`.
   */
  function baseIsDate(value) {
    return (0, _isObjectLike.default)(value) && (0, _baseGetTag.default)(value) == dateTag;
  }
  var _default = baseIsDate;
  _exports.default = _default;
});