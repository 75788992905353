define("lodash/_isMaskable", ["exports", "lodash/_coreJsData", "lodash/isFunction", "lodash/stubFalse"], function (_exports, _coreJsData, _isFunction, _stubFalse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Checks if `func` is capable of being masked.
   *
   * @private
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `func` is maskable, else `false`.
   */
  var isMaskable = _coreJsData.default ? _isFunction.default : _stubFalse.default;
  var _default = isMaskable;
  _exports.default = _default;
});