define("ember-data-model-fragments/attributes/index", ["exports", "ember-data-model-fragments/attributes/fragment", "ember-data-model-fragments/attributes/fragment-array", "ember-data-model-fragments/attributes/array", "ember-data-model-fragments/attributes/fragment-owner"], function (_exports, _fragment, _fragmentArray, _array, _fragmentOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "array", {
    enumerable: true,
    get: function () {
      return _array.default;
    }
  });
  Object.defineProperty(_exports, "fragment", {
    enumerable: true,
    get: function () {
      return _fragment.default;
    }
  });
  Object.defineProperty(_exports, "fragmentArray", {
    enumerable: true,
    get: function () {
      return _fragmentArray.default;
    }
  });
  Object.defineProperty(_exports, "fragmentOwner", {
    enumerable: true,
    get: function () {
      return _fragmentOwner.default;
    }
  });
});