define("ember-bootstrap-icons/components/bs-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <i class="bi-{{@name}}" ...attributes></i>
  
  */
  {
    "id": "5AHUq9YF",
    "block": "[[[11,\"i\"],[16,0,[29,[\"bi-\",[30,1]]]],[17,2],[12],[13],[1,\"\\n\"]],[\"@name\",\"&attrs\"],false,[]]",
    "moduleName": "ember-bootstrap-icons/components/bs-icon.hbs",
    "isStrictMode": false
  });
  class BsIconComponent extends _component2.default {}
  _exports.default = BsIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BsIconComponent);
});