define("ember-moment/helpers/is-same", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params, _ref) {
      let {
        precision,
        locale,
        timeZone
      } = _ref;
      this._super(...arguments);
      const moment = this.moment;
      const {
        length
      } = params;
      const args = [];
      const comparisonArgs = [];
      if (length === 1) {
        comparisonArgs.push(params[0]);
      } else if (length === 2) {
        args.push(params[0]);
        comparisonArgs.push(params[1]);
      }
      return this.morphMoment(moment.moment(...args), {
        locale,
        timeZone
      }).isSame(...comparisonArgs, precision);
    })
  });
  _exports.default = _default;
});