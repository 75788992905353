define("lodash/_getPrototype", ["exports", "lodash/_overArg"], function (_exports, _overArg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Built-in value references. */
  var getPrototype = (0, _overArg.default)(Object.getPrototypeOf, Object);
  var _default = getPrototype;
  _exports.default = _default;
});