define("ember-data-model-fragments/util/instance-of-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isInstanceOfType;
  // Check whether a object is an instance of the given type, respecting model
  // factory injections
  function isInstanceOfType(type, obj) {
    return obj instanceof type;
  }
});