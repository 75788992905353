define("ember-data-model-fragments/util/meta-type-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = metaTypeFor;
  // Create a unique type string for the combination of fragment property type,
  // transform type (or fragment model), and polymorphic type key
  function metaTypeFor(name, type, options) {
    let metaType = `-mf-${name}`;
    if (type) {
      metaType += `$${type}`;
    }
    if (options && options.polymorphic) {
      let typeKey = options.typeKey || 'type';
      typeKey = typeof typeKey === 'function' ? '__dynamic__' : typeKey;
      metaType += `$${typeKey}`;
    }
    return metaType;
  }
});