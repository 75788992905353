define("ember-responsive/null-match-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * Stub function that is `matchMedia` API compatible but always returns
   * `false`. Useful for server-side environments like FastBoot where there
   * is no viewport.
   */
  function _default() {
    return {
      matches: false
    };
  }
});