define("lodash/_baseSampleSize", ["exports", "lodash/_baseClamp", "lodash/_shuffleSelf", "lodash/values"], function (_exports, _baseClamp, _shuffleSelf, _values) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.sampleSize` without param guards.
   *
   * @private
   * @param {Array|Object} collection The collection to sample.
   * @param {number} n The number of elements to sample.
   * @returns {Array} Returns the random elements.
   */
  function baseSampleSize(collection, n) {
    var array = (0, _values.default)(collection);
    return (0, _shuffleSelf.default)(array, (0, _baseClamp.default)(n, 0, array.length));
  }
  var _default = baseSampleSize;
  _exports.default = _default;
});