define("lodash/_initCloneObject", ["exports", "lodash/_baseCreate", "lodash/_getPrototype", "lodash/_isPrototype"], function (_exports, _baseCreate, _getPrototype, _isPrototype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Initializes an object clone.
   *
   * @private
   * @param {Object} object The object to clone.
   * @returns {Object} Returns the initialized clone.
   */
  function initCloneObject(object) {
    return typeof object.constructor == 'function' && !(0, _isPrototype.default)(object) ? (0, _baseCreate.default)((0, _getPrototype.default)(object)) : {};
  }
  var _default = initCloneObject;
  _exports.default = _default;
});