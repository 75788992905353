define("lodash/_baseSample", ["exports", "lodash/_arraySample", "lodash/values"], function (_exports, _arraySample, _values) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.sample`.
   *
   * @private
   * @param {Array|Object} collection The collection to sample.
   * @returns {*} Returns the random element.
   */
  function baseSample(collection) {
    return (0, _arraySample.default)((0, _values.default)(collection));
  }
  var _default = baseSample;
  _exports.default = _default;
});