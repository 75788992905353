define("lodash/_freeGlobal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Detect free variable `global` from Node. */
  var freeGlobal = typeof global == 'object' && global && global.Object === Object && global;
  var _default = freeGlobal;
  _exports.default = _default;
});