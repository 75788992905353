define("lodash/_arrayIncludes", ["exports", "lodash/_baseIndexOf"], function (_exports, _baseIndexOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A specialized version of `_.includes` for arrays without support for
   * specifying an index to search from.
   *
   * @private
   * @param {Array} [array] The array to inspect.
   * @param {*} target The value to search for.
   * @returns {boolean} Returns `true` if `target` is found, else `false`.
   */
  function arrayIncludes(array, value) {
    var length = array == null ? 0 : array.length;
    return !!length && (0, _baseIndexOf.default)(array, value, 0) > -1;
  }
  var _default = arrayIncludes;
  _exports.default = _default;
});