define("lodash/sortedUniq", ["exports", "lodash/_baseSortedUniq"], function (_exports, _baseSortedUniq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This method is like `_.uniq` except that it's designed and optimized
   * for sorted arrays.
   *
   * @static
   * @memberOf _
   * @since 4.0.0
   * @category Array
   * @param {Array} array The array to inspect.
   * @returns {Array} Returns the new duplicate free array.
   * @example
   *
   * _.sortedUniq([1, 1, 2]);
   * // => [1, 2]
   */
  function sortedUniq(array) {
    return array && array.length ? (0, _baseSortedUniq.default)(array) : [];
  }
  var _default = sortedUniq;
  _exports.default = _default;
});