define("ember-moment/helpers/-base", ["exports", "@ember/runloop", "@ember/component/helper", "@ember/object", "@ember/service"], function (_exports, _runloop, _helper, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-classes
  var _default = _helper.default.extend({
    moment: (0, _service.inject)(),
    disableInterval: false,
    get globalAllowEmpty() {
      return this.moment.__config__.allowEmpty;
    },
    supportsGlobalAllowEmpty: true,
    // eslint-disable-next-line ember/no-observers
    localeOrTimeZoneChanged: (0, _object.observer)('moment.locale', 'moment.timeZone', function () {
      this.recompute();
    }),
    compute(value, _ref) {
      let {
        interval
      } = _ref;
      if (this.disableInterval) {
        return;
      }
      this.clearTimer();
      if (interval) {
        /*
         * NOTE: intentionally a setTimeout so tests do not block on it
         * as the run loop queue is never clear so tests will stay locked waiting
         * for queue to clear.
         */
        this.intervalTimer = setTimeout(() => {
          (0, _runloop.run)(() => this.recompute());
        }, parseInt(interval, 10));
      }
    },
    morphMoment(time, _ref2) {
      let {
        locale,
        timeZone
      } = _ref2;
      const momentService = this.moment;
      locale = locale || momentService.locale;
      timeZone = timeZone || momentService.timeZone;
      if (locale && time.locale) {
        time = time.locale(locale);
      }
      if (timeZone && time.tz) {
        time = time.tz(timeZone);
      }
      return time;
    },
    clearTimer() {
      clearTimeout(this.intervalTimer);
    },
    destroy() {
      this.clearTimer();
      this._super(...arguments);
    }
  });
  _exports.default = _default;
});