define("ember-cp-validations/validators/confirmation", ["exports", "@ember/debug", "ember-cp-validations/-private/ember-validator"], function (_exports, _debug, _emberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates that the attribute has the same value as the one of the declared attribute.
   *
   *  ## Examples
   *
   *  ```javascript
   *  email: validator('format', {
   *    type: 'email'
   *  })
   *  verifiedEmail: validator('confirmation', {
   *    on: 'email',
   *    message: 'Email addresses do not match'
   *  })
   *  ```
   *
   *  @class Confirmation
   *  @module Validators
   *  @extends Base
   */
  const Confirmation = _emberValidator.default.extend({
    _evType: 'confirmation'
  });
  Confirmation.reopenClass({
    getDependentsFor(attribute, options) {
      let on = options.on;
      (false && !(typeof on === 'string') && (0, _debug.assert)(`[validator:confirmation] [${attribute}] 'on' must be a string`, typeof on === 'string'));
      return on ? [`model.${on}`] : [];
    }
  });
  var _default = Confirmation;
  _exports.default = _default;
});