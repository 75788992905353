define("ember-cli-string-helpers/helpers/classify", ["exports", "@ember/component/helper", "@ember/string", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _string, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.classify = void 0;
  const classify = (0, _createStringHelper.default)(_string.classify);
  _exports.classify = classify;
  var _default = (0, _helper.helper)(classify);
  _exports.default = _default;
});