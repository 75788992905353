define("lodash/_parent", ["exports", "lodash/_baseGet", "lodash/_baseSlice"], function (_exports, _baseGet, _baseSlice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets the parent value at `path` of `object`.
   *
   * @private
   * @param {Object} object The object to query.
   * @param {Array} path The path to get the parent value of.
   * @returns {*} Returns the parent value.
   */
  function parent(object, path) {
    return path.length < 2 ? object : (0, _baseGet.default)(object, (0, _baseSlice.default)(path, 0, -1));
  }
  var _default = parent;
  _exports.default = _default;
});