import { deprecate } from '@ember/debug';
/**
  Copy properties from a source object to a target object. Source arguments remain unchanged.

  ```javascript
  import { assign } from '@ember/polyfills';

  var a = { first: 'Yehuda' };
  var b = { last: 'Katz' };
  var c = { company: 'Other Company' };
  var d = { company: 'Tilde Inc.' };
  assign(a, b, c, d); // a === { first: 'Yehuda', last: 'Katz', company: 'Tilde Inc.' };
  ```

  @method assign
  @for @ember/polyfills
  @param {Object} target The object to assign into
  @param {Object} ...args The objects to copy properties from
  @return {Object}
  @public
  @static
*/
export function assign(target, ...rest) {
  deprecate('Use of `assign` has been deprecated. Please use `Object.assign` or the spread operator instead.', false, {
    id: 'ember-polyfills.deprecate-assign',
    until: '5.0.0',
    url: 'https://deprecations.emberjs.com/v4.x/#toc_ember-polyfills-deprecate-assign',
    for: 'ember-source',
    since: {
      available: '4.0.0',
      enabled: '4.0.0'
    }
  });
  return Object.assign(target, ...rest);
}