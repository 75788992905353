define("lodash/number", ["exports", "lodash/clamp", "lodash/inRange", "lodash/random", "lodash/number.default"], function (_exports, _clamp, _inRange, _random, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "clamp", {
    enumerable: true,
    get: function () {
      return _clamp.default;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _number.default;
    }
  });
  Object.defineProperty(_exports, "inRange", {
    enumerable: true,
    get: function () {
      return _inRange.default;
    }
  });
  Object.defineProperty(_exports, "random", {
    enumerable: true,
    get: function () {
      return _random.default;
    }
  });
});