define("lodash/_baseForOwnRight", ["exports", "lodash/_baseForRight", "lodash/keys"], function (_exports, _baseForRight, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.forOwnRight` without support for iteratee shorthands.
   *
   * @private
   * @param {Object} object The object to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @returns {Object} Returns `object`.
   */
  function baseForOwnRight(object, iteratee) {
    return object && (0, _baseForRight.default)(object, iteratee, _keys.default);
  }
  var _default = baseForOwnRight;
  _exports.default = _default;
});