define("ember-radio-button/components/radio-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    <label class="ember-radio-button {{if this.checked this.checkedClass}} {{this.joinedClassNames}}" for={{@radioId}}>
      <RadioButtonInput
        class={{@radioClass}}
        id={{@radioId}}
        autofocus={{@autofocus}}
        disabled={{@disabled}}
        name={{@name}}
        required={{@required}}
        tabindex={{@tabindex}}
        @groupValue={{@groupValue}}
        @checked={{this.checked}}
        @value={{@value}}
        aria-labelledby={{@ariaLabelledby}}
        aria-describedby={{@ariaDescribedby}}
        @changed={{this.changed}}
      />
      {{yield}}
    </label>
  {{else}}
    <RadioButtonInput
      class={{@radioClass}}
      id={{@radioId}}
      autofocus={{@autofocus}}
      disabled={{@disabled}}
      name={{@name}}
      required={{@required}}
      tabindex={{@tabindex}}
      @groupValue={{@groupValue}}
      @checked={{this.checked}}
      @value={{@value}}
      aria-labelledby={{@ariaLabelledby}}
      aria-describedby={{@ariaDescribedby}}
      @changed={{this.changed}}
    />
  {{/if}}
  
  */
  {
    "id": "yuBKpAp0",
    "block": "[[[41,[48,[30,12]],[[[1,\"  \"],[10,\"label\"],[15,0,[29,[\"ember-radio-button \",[52,[30,0,[\"checked\"]],[30,0,[\"checkedClass\"]]],\" \",[30,0,[\"joinedClassNames\"]]]]],[15,\"for\",[30,1]],[12],[1,\"\\n    \"],[8,[39,2],[[16,0,[30,2]],[16,1,[30,1]],[16,\"autofocus\",[30,3]],[16,\"disabled\",[30,4]],[16,3,[30,5]],[16,\"required\",[30,6]],[16,\"tabindex\",[30,7]],[16,\"aria-labelledby\",[30,8]],[16,\"aria-describedby\",[30,9]]],[[\"@groupValue\",\"@checked\",\"@value\",\"@changed\"],[[30,10],[30,0,[\"checked\"]],[30,11],[30,0,[\"changed\"]]]],null],[1,\"\\n    \"],[18,12,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[16,0,[30,2]],[16,1,[30,1]],[16,\"autofocus\",[30,3]],[16,\"disabled\",[30,4]],[16,3,[30,5]],[16,\"required\",[30,6]],[16,\"tabindex\",[30,7]],[16,\"aria-labelledby\",[30,8]],[16,\"aria-describedby\",[30,9]]],[[\"@groupValue\",\"@checked\",\"@value\",\"@changed\"],[[30,10],[30,0,[\"checked\"]],[30,11],[30,0,[\"changed\"]]]],null],[1,\"\\n\"]],[]]]],[\"@radioId\",\"@radioClass\",\"@autofocus\",\"@disabled\",\"@name\",\"@required\",\"@tabindex\",\"@ariaLabelledby\",\"@ariaDescribedby\",\"@groupValue\",\"@value\",\"&default\"],false,[\"if\",\"has-block\",\"radio-button-input\",\"yield\"]]",
    "moduleName": "ember-radio-button/components/radio-button.hbs",
    "isStrictMode": false
  });
  let RadioButtonComponent = (_class = class RadioButtonComponent extends _component2.default {
    // value - passed in, required, the value for this radio button
    // groupValue - passed in, required, the currently selected value

    // optionally passed in:
    // disabled - boolean
    // required - boolean
    // name - string
    // radioClass - string
    // radioId - string
    // ariaLabelledby - string
    // ariaDescribedby - string

    get joinedClassNames() {
      const classNames = this.args.classNames;
      if (classNames && classNames.length && classNames.join) {
        return classNames.join(' ');
      }
      return classNames;
    }
    get checkedClass() {
      return this.args.checkedClass || 'checked';
    }
    get checked() {
      return (0, _utils.isEqual)(this.args.groupValue, this.args.value);
    }
    changed(newValue) {
      let changedAction = this.args.changed;

      // providing a closure action is optional
      if (changedAction) {
        changedAction(newValue);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "changed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changed"), _class.prototype)), _class);
  _exports.default = RadioButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioButtonComponent);
});