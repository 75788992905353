define("lodash/_customOmitClone", ["exports", "lodash/isPlainObject"], function (_exports, _isPlainObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Used by `_.omit` to customize its `_.cloneDeep` use to only clone plain
   * objects.
   *
   * @private
   * @param {*} value The value to inspect.
   * @param {string} key The key of the property to inspect.
   * @returns {*} Returns the uncloned value or `undefined` to defer cloning to `_.cloneDeep`.
   */
  function customOmitClone(value) {
    return (0, _isPlainObject.default)(value) ? undefined : value;
  }
  var _default = customOmitClone;
  _exports.default = _default;
});