define("ember-moment/helpers/now", ["exports", "moment", "ember-moment/helpers/-base"], function (_exports, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    compute() {
      this._super(...arguments);
      const momentService = this.moment;
      return momentService.moment(_moment.default.now());
    }
  });
  _exports.default = _default;
});