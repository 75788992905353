define("ember-moment/helpers/moment-to", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (_ref, _ref2) {
      let [datetime, ...params] = _ref;
      let {
        hideAffix,
        locale,
        timeZone
      } = _ref2;
      this._super(...arguments);
      const moment = this.moment;
      return this.morphMoment(moment.moment(datetime), {
        locale,
        timeZone
      }).to(...params, hideAffix);
    })
  });
  _exports.default = _default;
});