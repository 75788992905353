define("lodash/_reEscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Used to match template delimiters. */
  var reEscape = /<%-([\s\S]+?)%>/g;
  var _default = reEscape;
  _exports.default = _default;
});